import React from "react";
import ReactDOM from "react-dom";
import { Trans } from '@lingui/macro';
import { printConsole } from "../helpers";
import "./ToolTip.scss";
import SessionSignal from './SessionSignal';

export default class ToolTip extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      visible: false,
      x: 0,
      y: 0,
      type: "none",
      canTurnOffUserVideo: false,
      notInCall: []
    };
    this.invite_slider = React.createRef();
    this.joinees_slider = React.createRef();
    this.sessionSignal = new SessionSignal({},true, props.selectedGroupId);//initialise the object to recieve the in session signal. If signal needs to send then initialise the user details
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.canTurnOffUserVideo!==prevState.canTurnOffUserVideo && nextProps.canTurnOffUserVideo !== undefined){
        return {canTurnOffUserVideo: nextProps.canTurnOffUserVideo};
    }    
    else return null;
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClick, false);
    this.sessionSignal.listener('user-is-in-session', this.updateInSessionUser);   //TZ-2490
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClick, false);
    this.sessionSignal.removeListener('user-is-in-session', this.updateInSessionUser);   //TZ-2490
  }

  handleClick = (e) => {
    e.preventDefault();
    //console.log(e.target);
    //console.log(this.props.inputRef.current);
    //console.log(this.props.inputRef.current.invite_slider.current);
    //console.log(this.props.inputRef.current.joinees_slider.current);
    //return;
    if (e.target.type !== "text" && ((this.props.inputRef.current.joinees_slider.current !== null && !this.props.inputRef.current.joinees_slider.current.contains(e.target))
    || (this.props.inputRef.current.invite_slider.current !== null && !this.props.inputRef.current.invite_slider.current.contains(e.target))) ){
    //if(e.target !== undefined && e.target.id !== 'tooltip' && e.target.id !== 'joineesTooltip' && e.target.id !== 'invite_slider' && e.target.id !== 'invite_users' && e.target.id.includes("-link") === false) {
      //printConsole("Outside the component is clicked..");
      if ((document.getElementById("joineesTooltip") === null || document.getElementById("joineesTooltip") === undefined) && 
         (document.getElementById("invite_users") === null || document.getElementById("invite_users") === undefined)) return;
        let el1 = document.getElementById("joineesTooltip") && document.getElementById("joineesTooltip").parentElement;
        //printConsole(el1);
        //printConsole(this.props.inputRef.current.joinees_slider.current.classList.contains("on"));
        if (this.props.inputRef.current.joinees_slider.current != null && this.props.inputRef.current.joinees_slider.current.classList.contains("on")) {
            //printConsole("tooltip element");
            let rect1 = el1.getBoundingClientRect();
            //printConsole(rect1);
            this.show(rect1);
        }

        let el2 = document.getElementById("invite_users") && document.getElementById("invite_users").parentElement;
        //printConsole(el2);  
        //printConsole(this.props.inputRef.current.invite_slider.current.classList.contains("on"));      
        if (this.props.inputRef.current.invite_slider.current != null && this.props.inputRef.current.invite_slider.current.classList.contains("on")) {
            //printConsole("invite_slider element");
            let rect2 = el2.getBoundingClientRect();
            //printConsole(rect2);                
            this.show(rect2);
        }
    }
  }

  //TZ-2490
  updateInSessionUser = ({isInSession, userName}) => {
    printConsole(`user whose in session is ===========${isInSession}==============>: ${userName}`)
    if(isInSession){
      this.setState(prevState => {
        return {
          notInCall: prevState.notInCall.filter(email => email !== userName)
        };
      });
    }else{
      this.setState((prevState) => ({notInCall: [userName, ...prevState.notInCall]}))
    }
  }

  render() {
    let { state } = this;
    const {type, headerText, data, guest_user_enabled, creditsAvailable,
        isGuest, showHeaderActionButtons, sessionType, isAccountLevelExpertVideoDisabled, myPicture} = this.props;
    //printConsole(data);

    let visibility = state.visible === true ? "on" : "off";

    let style = {
      //left: state.x + window.scrollX + "px",
      top: type === "partcipant_list" ? (state.y + (window.scrollY - 30)) + "px" : state.y + window.scrollY + "px",
    };

    let classNames = {};

    if (state.type !== null && state.type !== "none") {
      classNames[state.type] = true;
    }

    classNames[visibility] = true;
    if (type === "invite_users") {
      return (
        <div ref={this.invite_slider}
        id="invite_slider"
        className={Object.keys(classNames).join(" ")}
        style={style}>
        <div className="tooltip-arrow"></div>
        <div className="tooltip-inner">
          <div>
              <div>
                <h2 className="tooltip-title-class" id="label-Id">
                  {headerText}
                </h2>
              </div>
              <div className="container-body">
                  {type=="invite_users" && data !== undefined && data.length > 0 ?
                    <>
                      {data.map((c,i) =>
                        <div key={i}>
                          <p className="row no-gutters">
                            <span className="col-2">
                            { c.myPicture !== undefined && c.myPicture !== null ?
                                <img className="session-profile-pic logo-profile-pic-list" alt="profile_logo" src={c.myPicture} ></img>
                                  :
                                <img className="session-profile-pic logo-profile-pic-list" src={"/profile.png"} alt='profile_logo' />
                            }
                            </span>
                          { c.label !== undefined ?
                              <span className="col-8 tooltip-names">{c.label}</span>
                                :
                              <span className="col-8 tooltip-names">{c.first_name+ " " +c.last_name}</span>
                          }
                          { 
                              <span className="col-2 tooltip-action-icons">&nbsp;&nbsp;&nbsp;
                                <a className={" cursor-pointer"} onClick={(e) => this.props.onClickUserCall(e)}>
                                  <i id={i+"-link"} value={c} className={ c.isBusy && !state.notInCall.includes(c.email)? "fas fa-phone-slash font-awesome-red fa-lg": "fas fa-phone font-awesome-green fa-lg"}></i>
                                </a>
                              </span>                                                            
                          }
                          </p>
                        </div>
                      )}
                      { (guest_user_enabled === true && creditsAvailable > 0 && !isGuest && showHeaderActionButtons) ?
                          <div>
                            <p className="row no-gutters">
                              <a className={" cursor-pointer col-12 tooltip-names"} onClick={(e) => this.props.showHideGuestForm(e)}>
                                  <Trans id="session.addGuest"></Trans>
                              </a>
                            </p>
                          </div>
                          :
                          ''
                      }
                    </>
                    :
                    ''
                  }
              </div>
            </div>
          </div>
        </div>
      )
    } else if (type=="partcipant_list") {
      return (
        <div ref={this.joinees_slider}
          id="tooltip"
          className={Object.keys(classNames).join(" ")}
          style={style}>
          <div className="tooltip-arrow"></div>
          <div className="tooltip-inner">
            <div>
              <div>
                <h2 className="tooltip-title-class" id="label-Id">
                  {headerText}
                </h2>
              </div>
              <div className="container-body">
                {type=="partcipant_list" && data !== undefined && data.length > 0 ?
                  <>
                    {data.map((e,i)=>{
                      let name = e.name;
                      if (e.name.includes("*") === true){
                        const tmpArr = e.name.split(" ");
                        tmpArr.splice(0, 1);
                        name = tmpArr.join(" ");
                      }
                      return (
                        <div key={i}>                          
                            {e.name.includes("*") === false ?
                              <p className="row no-gutters">
                                <span className="col-2 tooltip-image">
                                  { e.myPicture !== undefined && e.myPicture !== null ?
                                    <img className="session-profile-pic logo-profile-pic-list" alt="profile_logo" src={e.myPicture} ></img>
                                    :
                                    <img className="session-profile-pic logo-profile-pic-list" src={"/profile.png"} alt='profile_logo' />
                                  }
                                  
                                </span>
                                <span className="col-6 tooltip-names">
                                  {name}
                                </span>
                                <span className="col-4 tooltip-action-icons">
                                  {/* T32-413 -- Disabled Speaker of Remote user impleementation */}                                  
                                  { e.disableRemoteAudio === false ?
                                      <a className="cursor-pointer" onClick={(e) => this.props.onClickUserSpeaker(e)}>
                                          <i id={e.userId+"-link"} value={e} className={e.disableAudio ? "fas fa-volume-mute font-awesome-red fa-lg": "fas fa-volume-up fa-lg"}></i>
                                      </a>
                                      :
                                      <a className="cursor-pointer">
                                          <i id={e.userId+"-link"} value={e} className={"fas fa-volume-mute icon-disabled fa-lg"}></i>
                                      </a>
                                  }
                                  &nbsp;&nbsp;<a className="cursor-pointer" onClick={(e) => this.props.onClickUserMike(e)}><i id={e.userId+"-link"} value={e} className={e.disableMike ? "fas fa-microphone-slash font-awesome-red fa-lg": "fas fa-microphone fa-lg"}></i></a>
                                  { state.canTurnOffUserVideo === true ?
                                      <>&nbsp;&nbsp;<a className="cursor-pointer" onClick={/*TP-4538*/(e.grayedVideo === undefined || e.grayedVideo === false) && (e.isPaused === undefined || e.isPaused === false) ? (e) => this.props.onClickUserVideo(e) : () => {return null}}><i id={e.userId+"-link"} value={e} className={(e.grayedVideo === undefined || e.grayedVideo === false) && (e.isPaused === undefined || e.isPaused === false) ? e.disableVideo ? "fas fa-video-slash font-awesome-red fa-lg": "fas fa-video font-awesome-green fa-lg" : "fas fa-video-slash font-awesome-grey fa-lg"}></i></a></>
                                      :
                                      ''
                                  }
                                </span>
                              </p>
                              :
                              <p className="row no-gutters">
                                <span className="col-2">
                                  {  e.myPicture !== undefined && e.myPicture !== null ?
                                    <img className="session-profile-pic logo-profile-pic-list" alt="profile_logo" src={e.myPicture} ></img>
                                    :
                                    <img className="session-profile-pic logo-profile-pic-list" src={"/profile.png"} alt='profile_logo' />
                                  }
                                </span>
                                <span className="col-6 tooltip-names">
                                  <i className="fas fa-asterisk fa-xs"></i>&nbsp;&nbsp;{name}
                                </span>
                                <span className="col-4 tooltip-action-icons">                                  
                                  { e.disableRemoteAudio === false ?
                                        <a className="cursor-pointer" onClick={(e) => this.props.onClickUserSpeaker(e)}>
                                            <i id={e.userId+"-link"} value={e} className={e.disableAudio ? "fas fa-volume-mute font-awesome-red fa-lg": "fas fa-volume-up fa-lg"}></i>
                                        </a>
                                          :
                                        <a className="cursor-pointer">
                                          <i id={e.userId+"-link"} value={e} className={"fas fa-volume-mute icon-disabled fa-lg"}></i>
                                        </a>
                                  }
                                  &nbsp;&nbsp;<a className="cursor-pointer" onClick={(e) => this.props.onClickUserMike(e)}><i id={e.userId+"-link"} value={e} className={e.disableMike ? "fas fa-microphone-slash font-awesome-red fa-lg": "fas fa-microphone fa-lg"}></i></a>
                                  { /*TP-5453 & TP-3532*/isAccountLevelExpertVideoDisabled === false && state.canTurnOffUserVideo === true ?
                                      <>&nbsp;&nbsp;<a className="cursor-pointer" onClick={/*TP-4538 TP-3774*/(e.grayedVideo === undefined || e.grayedVideo === false) && (e.isPaused === undefined || e.isPaused === false) ? (e) => this.props.onClickUserVideo(e) : () => {return null}}><i id={e.userId+"-link"} value={e} className={/*TP-3774*/(e.grayedVideo === undefined || e.grayedVideo === false) && (e.isPaused === undefined || e.isPaused === false) ? e.disableVideo ? "fas fa-video-slash font-awesome-red fa-lg": "fas fa-video font-awesome-green fa-lg" : "fas fa-video-slash font-awesome-grey fa-lg"}></i></a></>
                                      :
                                      ''
                                  }
                                </span>
                              </p>
                            }
                        </div>
                      )
  
                    })}
                    <div>
                      <p className="row no-gutters">
                        <span className="col-2"> 
                          {  myPicture !== undefined && myPicture !== null ?
                                    <img className="session-profile-pic logo-profile-pic-list" alt="profile_logo" src={myPicture} ></img>
                                    :
                                    <img className="session-profile-pic logo-profile-pic-list" src={"/profile.png"} alt='profile_logo' />
                                  }
                        </span>
                        <span className="col-10">
                          <i className="fas fa-asterisk fa-xs"></i>&nbsp;&nbsp;<Trans>You</Trans>
                        </span>
                      </p>
                    </div>   
                  </>           
                  :
                  ''
                }
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  pastShow(hoverRect) {
    // position the tooltip after showing it

    let ttNode = ReactDOM.findDOMNode(this);

    if (ttNode != null) {
      let x = 0,
        y = 0;

      const docWidth = document.documentElement.clientWidth,
        docHeight = document.documentElement.clientHeight;

      let rx = hoverRect.x + hoverRect.width, // most right x
        lx = hoverRect.x, // most left x
        ty = hoverRect.y, // most top y
        by = hoverRect.y + hoverRect.height; // most bottom y

      // tool tip rectange
      let ttRect = ttNode.getBoundingClientRect();

      let bBellow = by + ttRect.height <= window.scrollY + docHeight;

      let newState = {};

      if (bBellow) {
        y = by;

        x = lx + (hoverRect.width - ttRect.width);

        if (x < 0) {
          x = lx;
        }

        newState.type = "bottom";
      }

      newState = { ...newState, x: x, y: y };

      this.setState(newState);
    }
  }

  show(hoverRect) {
    let { pastShow } = this;

    if (!this.state.visible) {
      this.setState({ visible: true }, pastShow.bind(this, hoverRect));
    } else {
      this.setState({ visible: false });
    }
  }
}
